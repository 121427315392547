<template>
  <b-card>
    <FormBuilder
      label="Kategori Pertanyaan"
      create
      :fields="fields"
      :loading-submit="$store.state.questionCategory.loadingSubmit"
      @submit="submitData"
    />
  </b-card>
</template>

<script>
import FormBuilder from '@/layouts/components/FormBuilder.vue'

import {
  BCard,
} from 'bootstrap-vue'

import fields from '@/field/form/question-category'

export default {
  components: {
    BCard,
    FormBuilder,
  },
  setup() {
    return {
      fields,
    }
  },
  methods: {
    submitData(data, callback) {
      this.$store.dispatch('questionCategory/create', data)
        .then(callback)
    },
  },
}
</script>

<style scoped>

</style>
